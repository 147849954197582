// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_bannerContainer__2k9dR {\n  background: #000;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.8rem 1rem;\n}\n\n.style_infoIcon__3gc2p {\n  fill: #fff;\n  height: 1.2rem;\n  margin-right: 0.8rem;\n  width: 1.2rem;\n}\n.style_infoIcon__3gc2p > svg {\n  height: inherit;\n  width: inherit;\n}\n\n.style_bannerText__EdACk {\n  font-size: 0.8rem;\n  line-height: 1.2rem;\n}\n\n@media only screen and (min-width: 700px) {\n  .style_bannerText__EdACk {\n    font-size: 0.9rem;\n  }\n}\n@media only screen and (max-width: 900px) {\n  .style_bannerContainer__2k9dR {\n    margin-top: 48px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/Navbars/CustomerGlobalNavbar/style.module.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;AAHF;;AAMA;EACE,UAAA;EACA,cAAA;EACA,oBAAA;EACA,aAAA;AAHF;AAIE;EACE,eAAA;EACA,cAAA;AAFJ;;AAMA;EACE,iBAAA;EACA,mBAAA;AAHF;;AAMA;EACE;IACE,iBAAA;EAHF;AACF;AAMA;EACE;IACE,gBAAA;EAJF;AACF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.bannerContainer {\n  background: #000;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.8rem 1rem;\n}\n\n.infoIcon {\n  fill: #fff;\n  height: 1.2rem;\n  margin-right: 0.8rem;\n  width: 1.2rem;\n  & > svg {\n    height: inherit;\n    width: inherit;\n  }\n}\n\n.bannerText {\n  font-size: 0.8rem;\n  line-height: 1.2rem;\n}\n\n@media only screen and (min-width: 700px) {\n  .bannerText {\n    font-size: 0.9rem;\n  }\n}\n\n@media only screen and (max-width: 900px) {\n  .bannerContainer {\n    margin-top: 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": "style_bannerContainer__2k9dR",
	"infoIcon": "style_infoIcon__3gc2p",
	"bannerText": "style_bannerText__EdACk"
};
export default ___CSS_LOADER_EXPORT___;
