// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_agreeButton__3BnYC {\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n}\n\n.style_disclaimer__1fd1i {\n  color: #757575;\n  line-height: 22px;\n}\n\n.style_checkbox__1D5gQ {\n  height: 25px;\n  width: 25px;\n  border: 1px solid #e0e0e0;\n  border-radius: inherit;\n  margin-right: 15px;\n}\n.style_checkbox__1D5gQ.style_checked__15wgp {\n  background: #000;\n  border-color: #000;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.style_checkIcon__KVm0o {\n  height: 16px;\n  width: 16px;\n  fill: #fff;\n}", "",{"version":3,"sources":["webpack://src/fields/ImageUsageDisclaimer/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AADF;;AAIA;EACE,cAAA;EACA,iBAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;AADF;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;;AAIA;EACE,YAAA;EACA,WAAA;EACA,UAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.agreeButton {\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n}\n\n.disclaimer {\n  color: #757575;\n  line-height: 22px;\n}\n\n.checkbox {\n  height: 25px;\n  width: 25px;\n  border: 1px solid #e0e0e0;\n  border-radius: inherit;\n  margin-right: 15px;\n  &.checked {\n    background: #000;\n    border-color: #000;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n\n.checkIcon {\n  height: 16px;\n  width: 16px;\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreeButton": "style_agreeButton__3BnYC",
	"disclaimer": "style_disclaimer__1fd1i",
	"checkbox": "style_checkbox__1D5gQ",
	"checked": "style_checked__15wgp",
	"checkIcon": "style_checkIcon__KVm0o"
};
export default ___CSS_LOADER_EXPORT___;
