// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_heading__2UdGH {\n  font-size: 1.2rem;\n}\n\n.style_category__vEhUv {\n  background: #fff;\n  border-bottom: 1px solid #d4dae0;\n  border-radius: 0;\n  color: #000;\n  align-items: flex-start;\n  justify-content: space-between;\n  line-height: 1.4rem;\n  padding: 14px 0;\n  text-align: left;\n  width: 100%;\n}\n.style_category__vEhUv:hover {\n  box-shadow: none;\n}\n\n.style_categoryName__2MErM {\n  padding-right: 1rem;\n}\n\n.style_itemsCount__2BMYl {\n  font-weight: 400;\n  text-transform: lowercase;\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://src/components/Modals/CategoryNavigationModal/style.module.scss"],"names":[],"mappings":"AAIA;EACE,iBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,gCAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AAHF;AAIE;EACE,gBAAA;AAFJ;;AAMA;EACE,mBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,yBAAA;EACA,mBAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.heading {\n  font-size: 1.2rem;\n}\n\n.category {\n  background: #fff;\n  border-bottom: 1px solid $border-color-dark;\n  border-radius: 0;\n  color: #000;\n  align-items: flex-start;\n  justify-content: space-between;\n  line-height: 1.4rem;\n  padding: 14px 0;\n  text-align: left;\n  width: 100%;\n  &:hover {\n    box-shadow: none;\n  }\n}\n\n.categoryName {\n  padding-right: 1rem;\n}\n\n.itemsCount {\n  font-weight: 400;\n  text-transform: lowercase;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "style_heading__2UdGH",
	"category": "style_category__vEhUv",
	"categoryName": "style_categoryName__2MErM",
	"itemsCount": "style_itemsCount__2BMYl"
};
export default ___CSS_LOADER_EXPORT___;
