// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_mobileNavbarIcon__29jhy {\n  height: 30px;\n  width: 30px;\n}\n\n.style_customTipOptionIcon__18Lyo {\n  height: 1.4rem;\n  margin-top: 5px;\n  width: 1.4rem;\n}", "",{"version":3,"sources":["webpack://src/lib/_constants/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,aAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.mobileNavbarIcon {\n  height: 30px;\n  width: 30px;\n}\n\n.customTipOptionIcon {\n  height: 1.4rem;\n  margin-top: 5px;\n  width: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileNavbarIcon": "style_mobileNavbarIcon__29jhy",
	"customTipOptionIcon": "style_customTipOptionIcon__18Lyo"
};
export default ___CSS_LOADER_EXPORT___;
