// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_quantityCalculator__3atou {\n  height: 40px;\n}\n\n.style_quantityNumber__HXhht {\n  min-width: 20px;\n  max-width: 20px;\n}", "",{"version":3,"sources":["webpack://src/components/CustomerMenuElements/MenuItemDetail/ItemDetailsForm/ItemQuantityCalculator/style.module.scss"],"names":[],"mappings":"AAIA;EACE,YAAA;AAHF;;AAMA;EACE,eAAA;EACA,eAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.quantityCalculator {\n  height: 40px;\n}\n\n.quantityNumber {\n  min-width: 20px;\n  max-width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quantityCalculator": "style_quantityCalculator__3atou",
	"quantityNumber": "style_quantityNumber__HXhht"
};
export default ___CSS_LOADER_EXPORT___;
