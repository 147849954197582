// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__1pNmC {\n  max-width: 1200px;\n  margin: 0 auto 100px auto;\n  padding: 0 30px;\n}\n\n.style_callToAction__3sSyn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.style_callToAction__3sSyn > h2 {\n  font-size: 2rem;\n  margin-bottom: 50px;\n}\n\n@media only screen and (max-width: 500px) {\n  .style_container__1pNmC {\n    margin-bottom: 50px;\n  }\n\n  .style_callToAction__3sSyn > h2 {\n    text-align: center;\n  }\n}", "",{"version":3,"sources":["webpack://src/views/VirtualRestaurant/style.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,yBAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;AAEE;EACE,eAAA;EACA,mBAAA;AAAJ;;AAIA;EACE;IACE,mBAAA;EADF;;EAKE;IACE,kBAAA;EAFJ;AACF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.container {\n  max-width: 1200px;\n  margin: 0 auto 100px auto;\n  padding: 0 30px;\n}\n\n.callToAction {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  & > h2 {\n    font-size: 2rem;\n    margin-bottom: 50px;\n  }\n}\n\n@media only screen and (max-width: 500px) {\n  .container {\n    margin-bottom: 50px;\n  }\n\n  .callToAction {\n    & > h2 {\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__1pNmC",
	"callToAction": "style_callToAction__3sSyn"
};
export default ___CSS_LOADER_EXPORT___;
