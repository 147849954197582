// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_msgContainer__3qyYe {\n  text-align: center;\n  padding-top: 50px;\n  font-size: 1rem;\n  line-height: 25px;\n}", "",{"version":3,"sources":["webpack://src/fields/PageMsg/style.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.msgContainer {\n  text-align: center;\n  padding-top: 50px;\n  font-size: 1rem;\n  line-height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msgContainer": "style_msgContainer__3qyYe"
};
export default ___CSS_LOADER_EXPORT___;
