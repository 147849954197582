// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__3owvS {\n  margin: 10px 0;\n}\n\n.style_weekdayContainer__1oDvo {\n  display: flex;\n  margin-bottom: 0.6rem;\n  opacity: 0.6;\n}\n.style_weekdayContainer__1oDvo.style_isToday__DqTH3 {\n  font-weight: bold;\n  opacity: 1;\n}\n\n.style_weekday__9fyFM {\n  margin-right: 1rem;\n  text-transform: capitalize;\n  width: 80px;\n}", "",{"version":3,"sources":["webpack://src/components/ShopHoursTable/style.module.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;AAHF;;AAMA;EACE,aAAA;EACA,qBAAA;EACA,YAAA;AAHF;AAIE;EACE,iBAAA;EACA,UAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,0BAAA;EACA,WAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.container {\n  margin: 10px 0;\n}\n\n.weekdayContainer {\n  display: flex;\n  margin-bottom: 0.6rem;\n  opacity: 0.6;\n  &.isToday {\n    font-weight: bold;\n    opacity: 1;\n  }\n}\n\n.weekday {\n  margin-right: 1rem;\n  text-transform: capitalize;\n  width: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__3owvS",
	"weekdayContainer": "style_weekdayContainer__1oDvo",
	"isToday": "style_isToday__DqTH3",
	"weekday": "style_weekday__9fyFM"
};
export default ___CSS_LOADER_EXPORT___;
