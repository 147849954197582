// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import _pick from "lodash.pick";
import { set } from "object-path-immutable";
import humanparser from "humanparser";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { Link, PageMsg } from "fields";

// Icons
import { BagIcon } from "assets/Icons";

// Lib
import {
  FoodMenuFuncs,
  SendSingleTextMessageToMultipleNumbers,
} from "lib/functions";
import { BUTI, Customers } from "lib/services";

const { SendTextMessage } = BUTI.PostRequests;

const {
  CreateDoordashDelivery,
  CreatePostmatesDelivery,
} = Customers.PostRequests;

const DRIVER_SHARE_OF_TIP = 0.5; // 50%

const POSTMATES_DELIVERY_CONFIRMATION_ATTRIBUTES = [
  "created",
  "dropoff_deadline",
  "dropoff_eta",
  "id",
  "pickup_deadline",
  "pickup_eta",
  "pickup_ready",
  "tracking_url",
];

const _buildItemsListForDeliveryService = ({ orderItems = {} }) =>
  Object.values(orderItems).map((item) => {
    const {
      itemID: external_id,
      itemSimpleDescription = {},
      quantity = 1,
      size = "medium",
    } = item;
    const { itemName } = itemSimpleDescription;
    return { external_id, quantity, name: itemName, size };
  });

// Calculate the number of items in the delivery order
const _calculateNumItems = (orderItems = {}) =>
  Object.values(orderItems).reduce((num_items, item = {}) => {
    const { quantity = 0 } = item;
    return num_items + quantity;
  }, 0);

const _calcTotalOrderPriceBeforeTax = ({ cart }) =>
  Object.values(cart).reduce(
    (sum, detailsOfItemInCart) => {
      const {
        totalPriceBeforeTax,
        totalPriceBeforeTaxWithoutSale,
      } = FoodMenuFuncs.CalcTotalPriceBeforeTaxForItem({
        detailsOfItemInCart,
      });
      return {
        totalOrderPriceBeforeTax:
          sum.totalOrderPriceBeforeTax + parseFloat(totalPriceBeforeTax),
        totalOrderPriceBeforeTaxWithoutSale:
          sum.totalOrderPriceBeforeTaxWithoutSale +
          parseFloat(totalPriceBeforeTaxWithoutSale),
      };
    },
    { totalOrderPriceBeforeTax: 0, totalOrderPriceBeforeTaxWithoutSale: 0 }
  );

const _createDeliveryRequest = async ({
  orderDetails = {},
  orderID,
  shopBasicInfo = {},
  shopID,
}) => {
  const { deliveryDetails = {} } = orderDetails;
  const { courier = "doordash" } = deliveryDetails;

  try {
    if (courier === "postmates")
      return await _create_postmates_delivery({
        orderDetails,
        orderID,
        shopBasicInfo,
        shopID,
      });
    else if (courier === "doordash")
      return await _create_doordash_delivery({
        orderDetails,
        orderID,
        shopBasicInfo,
        shopID,
      });
  } catch {
    const { SendEmail } = BUTI.PostRequests;
    const notice_content = _createFailedDeliveryRequestNotice({
      orderDetails,
      orderID,
      shopBasicInfo,
      shopID,
    });
    await SendEmail({
      addresses: ["support@skiplinow.com"],
      body: notice_content,
      subject: "Failed to request delivery!",
    });
    await SendSingleTextMessageToMultipleNumbers({
      body: notice_content,
      phoneNumbers: { 1: "7145985560", 2: "9493466346" },
    });
    return set(orderDetails, "orderDeliveryTypeID", "pickUp");
  }
};

// ------------------------------------------------------------------------
// Create a Doordash delivery request

const _create_doordash_delivery = async ({
  orderDetails,
  orderID,
  shopBasicInfo,
  shopID,
}) => {
  const {
    customerName,
    deliveryDetails = {},
    feesForBUTI = {},
    orderItems,
    phoneNumber: customer_phone_number = "",
  } = orderDetails;
  const {
    firstName: first_name = "N/A",
    lastName: last_name = "N/A",
  } = humanparser.parseName(customerName);
  const {
    courierQuote,
    deliveryNotes: dropoff_instructions = "",
    dropoff_option,
  } = deliveryDetails;
  const { dropoff_address, order_value, pickup_address } = courierQuote;
  const { technologyFee = 0 } = feesForBUTI;
  const {
    name: external_business_name,
    phoneNumber: pickup_phone_number,
  } = shopBasicInfo;
  try {
    const { doordashDelivery } = await CreateDoordashDelivery({
      allow_unattended_delivery:
        dropoff_option === "unattended_delivery" ? true : false,
      customer: {
        email: "support@skiplinow.com",
        first_name: first_name || "N/A",
        last_name: last_name || "N/A",
        phone_number: customer_phone_number,
      },
      dropoff_address,
      dropoff_instructions,
      external_business_name,
      external_delivery_id: orderID,
      external_store_id: shopID,
      items: _buildItemsListForDeliveryService({ orderItems }),
      num_items: _calculateNumItems(orderItems),
      order_value,
      pickup_address,
      pickup_business_name: external_business_name,
      pickup_phone_number,
      tip: parseInt(technologyFee * DRIVER_SHARE_OF_TIP * 100),
    });
    const { id } = doordashDelivery;
    return set(orderDetails, "deliveryDetails.courierConfirmation", { id });
  } catch (error) {
    throw error;
  }
};

const _createDropoffNote = ({ deliveryNotes, phoneNumber, streetAddress2 }) =>
  (streetAddress2 ? `APT/UNIT/SUITE: ${streetAddress2} | ` : "") +
  `Call ${_formatPhoneNumber(phoneNumber)} when you arrive` +
  (deliveryNotes ? ` | Guest Notes: ${deliveryNotes}` : "");

const _createEmptyCartMessage = ({ pastOrdersButton, viewMenuButton }) => (
  <PageMsg>
    <div className={Style.bagIcon}>
      <BagIcon />
    </div>
    <p style={{ fontSize: "1.1rem", marginBottom: "2rem" }}>
      <strong>Your cart is empty</strong>
      <br />
      Add items to get started
    </p>
    {viewMenuButton}
    {pastOrdersButton}
  </PageMsg>
);

// ----------------------------------------------------------
// Create an email notice when the delivery request failed

const _createFailedDeliveryRequestNotice = ({
  orderDetails = {},
  orderID = "",
  shopBasicInfo = {},
  shopID = "",
}) => {
  const {
    customerName,
    deliveryDetails = {},
    phoneNumber: customerPhone = "",
  } = orderDetails;
  const { deliveryAddress = {}, deliveryNotes = "" } = deliveryDetails;
  const {
    streetAddress1,
    streetAddress2 = "",
    city,
    state,
    zip,
  } = deliveryAddress;
  const {
    address,
    name: shopName,
    phoneNumber: shopPhoneNumber = "",
  } = shopBasicInfo;
  return `Hi,\r\n\r\nOur system failed to request a delivery for order #${orderID} at ${shopName}. Here is the details:\r\n\r\n--- Customer ---\r\nName: ${customerName}\r\nPhone: ${customerPhone}\r\nAddress: ${streetAddress1}, ${city}, ${state}, ${zip}\r\nUnit/Apt/Floor: ${streetAddress2}\r\nDropoff Instruction: ${deliveryNotes}\r\n\r\n--- Restaurant ---\r\nName: ${shopName}\r\nAddress: ${address}\r\nPhone: ${shopPhoneNumber}\r\nMerchant Portal: https://admin.skiplinow.com/${shopID}\r\n\r\nTo request another delivery: https://www.doordash.com/drive/portal\r\n\r\nBest,\r\n\r\nSkipli`;
};

const _createFailedOrderMessage = () => (
  <PageMsg>
    <div>Our apology!</div>
    <p className={Style.submittedMsg}>
      We could not send your order. Please refresh the page and try again.
    </p>
  </PageMsg>
);

// ------------------------------------------------------------------------
// Create a Postmates delivery request

const _create_postmates_delivery = async ({
  orderDetails,
  orderID,
  shopBasicInfo,
  shopID,
}) => {
  const {
    customerName,
    deliveryDetails,
    orderItems,
    phoneNumber,
  } = orderDetails;
  const { courierQuote, deliveryAddress, deliveryNotes = "" } = deliveryDetails;
  const { streetAddress1, streetAddress2, city, state, zip } = deliveryAddress;
  const {
    address = "",
    name: shopName = "",
    phoneNumbersToReceiveOrders,
  } = shopBasicInfo;

  try {
    const { postmatesDelivery } = await CreatePostmatesDelivery({
      dropoff_address: `${streetAddress1}, ${city}, ${state}, ${zip}`,
      dropoff_name: customerName,
      dropoff_notes: _createDropoffNote({
        deliveryNotes,
        phoneNumber,
        streetAddress2,
      }),
      dropoff_phone_number: phoneNumber,
      manifest: `Food delivery for ${customerName}`,
      manifest_items: _buildItemsListForDeliveryService({ orderItems }),
      manifest_reference: `ShopID: ${shopID} | OrderID: ${orderID}`,
      pickup_address: address,
      pickup_name: shopName,
      pickup_phone_number: _findShopPhoneNumber(phoneNumbersToReceiveOrders),
      quote_id: courierQuote.id,
      undeliverable_action: "leave_at_door",
    });
    return set(
      orderDetails,
      "deliveryDetails.courierConfirmation",
      _pick(postmatesDelivery, POSTMATES_DELIVERY_CONFIRMATION_ATTRIBUTES)
    );
  } catch (error) {
    throw error;
  }
};

const _createSubmittedOrderMessage = ({
  pastOrdersButton,
  postmatesTrackingURL,
  submittedOrderDeliveryTypeID,
}) => (
  <PageMsg>
    <strong>Thank you!</strong>
    <p className={Style.submittedMsg}>
      {submittedOrderDeliveryTypeID === "inStore"
        ? "Your order will be out shortly"
        : "You will receive a text message once your order is ready"}
    </p>
    {postmatesTrackingURL && (
      <Link href={postmatesTrackingURL}>Track your delivery here</Link>
    )}
    {pastOrdersButton}
  </PageMsg>
);

const _findShopPhoneNumber = (phoneNumbers = {}) => {
  const result = Object.values(phoneNumbers).reduce(
    (number, result) => (result ? result : number),
    ""
  );
  return result || "6789991044";
};

const _generateOrderID = () =>
  String(Math.floor(100000 + Math.random() * 900000)) +
  String.fromCharCode(97 + Math.floor(Math.random() * 26));

const _getOrderNotificationPhoneNumbers = ({ phoneNumbers = {} }) =>
  Object.keys(phoneNumbers).reduce((result, id) => {
    const phone = phoneNumbers[id];
    return set(result, id, phone);
  }, {});

// Send the tracking link to the guest
const _sendTrackingLinkToGuest = async ({
  order_id,
  phone_number,
  shop_address,
  shop_id,
  shop_name,
}) => {
  await SendTextMessage({
    body: `We sent your order #${order_id} to ${shop_name} at ${shop_address}. Use the link below to track your order https://skipli.co/shop/${shop_id}/order/${order_id}`,
    to: phone_number,
  });
};

export {
  _calcTotalOrderPriceBeforeTax,
  _createDeliveryRequest,
  _createEmptyCartMessage,
  _createFailedOrderMessage,
  _createSubmittedOrderMessage,
  _generateOrderID,
  _getOrderNotificationPhoneNumbers,
  _sendTrackingLinkToGuest,
};
