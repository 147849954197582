// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import React from "react";
import PropTypes from "prop-types";

import { LabelOption, SwitchOption } from "../Options";

// Style
import Style from "./style.module.scss";

const AllergySection = ({ options, onSelect }) =>
  options.map((option, index) => {
    const { id, label, colorCode, icon, isSelected } = option;
    const style = {
      container: {
        opacity: isSelected ? "1" : "0.4"
      },
      circle: {
        borderColor: colorCode
      }
    };
    return (
      <div className={Style.allergyOption} key={id}>
        <LabelOption
          icon={<div className={Style.iconContainer}>{icon}</div>}
          label={label}
          onSelect={() =>
            onSelect({
              optionPath: [index, "isSelected"],
              newValue: !isSelected
            })
          }
          style={style}
        />
      </div>
    );
  });

AllergySection.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func
};

const DietaryRestrictionsSection = ({ options, onSelect }) =>
  options.map((option, index) => {
    const { id, isSelected } = option;
    return (
      <div key={id}>
        <SwitchOption
          {...option}
          onSelect={() =>
            onSelect({
              optionPath: [index, "isSelected"],
              newValue: !isSelected
            })
          }
        />
      </div>
    );
  });

DietaryRestrictionsSection.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func
};

export { AllergySection, DietaryRestrictionsSection };
