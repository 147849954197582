// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_comingSoonButton__3T2OR {\n  background: #f0f0f0;\n  font-weight: bold;\n  height: 40px;\n  padding: 0 15px;\n  pointer-events: none;\n  text-transform: uppercase;\n}", "",{"version":3,"sources":["webpack://src/fields/ComingSoonButton/style.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,oBAAA;EACA,yBAAA;AADF","sourcesContent":["// BUTI Corp All right Reserved ©\n\n.comingSoonButton {\n  background: #f0f0f0;\n  font-weight: bold;\n  height: 40px;\n  padding: 0 15px;\n  pointer-events: none;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comingSoonButton": "style_comingSoonButton__3T2OR"
};
export default ___CSS_LOADER_EXPORT___;
