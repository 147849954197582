// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import BUTI from "./BUTI";
import Customers from "./Customers";
import Merchants from "./Merchants";

const LIVE_MODE_ENABLED = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV !== "development";
};

const DEV_BASE_URL = "https://localhost:8000";
const PROD_BASE_URL = "https://buti-diners-backend.herokuapp.com";

const DEV_MERCHANT_DASHBOARD_URL = "https://localhost:4000";
const PROD_MERCHANT_DASHBOARD_URL = "https://www.newskipliorder.com";

const BASE_URL = LIVE_MODE_ENABLED() ? PROD_BASE_URL : DEV_BASE_URL;

const FRONT_END_DEV_BASE_URL = "https://localhost:3000";
const FRONT_END_PROD_BASE_URL = "https://skiplinow.com";

const FRONT_END_BASE_URL = LIVE_MODE_ENABLED()
  ? FRONT_END_PROD_BASE_URL
  : FRONT_END_DEV_BASE_URL;

const RECEIPT_BASE_URL = LIVE_MODE_ENABLED()
  ? FRONT_END_PROD_BASE_URL
  : FRONT_END_DEV_BASE_URL;

const MERCHANT_DASHBOARD_URL = LIVE_MODE_ENABLED() ? PROD_MERCHANT_DASHBOARD_URL : DEV_MERCHANT_DASHBOARD_URL;

// -------------- BUTI DINERS METHODS --------------
// POST METHODS
const ADD_EMAIL_TO_MAILING_LIST = `${BASE_URL}/addEmail`;

// -------------- RESTAURANT METHODS --------------
// GET METHODS
const CREATE_STRIPE_CONNECT_ACCOUNT = `${BASE_URL}/createStripeConnectAccount`;

// POST METHODS
const POST_NEW_SHOP_STRIPE_CONNECT_INFO = `${BASE_URL}/updateShopStripeConnectInfo`;

export {
  BUTI,
  Customers,
  LIVE_MODE_ENABLED,
  Merchants,
  ADD_EMAIL_TO_MAILING_LIST,
  CREATE_STRIPE_CONNECT_ACCOUNT,
  FRONT_END_BASE_URL,
  MERCHANT_DASHBOARD_URL,
  POST_NEW_SHOP_STRIPE_CONNECT_INFO,
  RECEIPT_BASE_URL,
};
