// BUTI DINERS, INC. All right Reserved ©

import AutoPhone from "./AutoPhone";
import Blog from "./Blog";
import BlogPost from "./BlogPost";
import Cart from "./Cart";
import ContactlessOrdering from "./ContactlessOrdering";
import ErrorPage from "./ErrorPage";
import GetStarted from "./GetStarted";
import GoogleFrontPage from "./GoogleFrontPage";
import HomePage from "./HomePage";
import MatchingShops from "./MatchingShops";
import Oppties from "./Oppties";
import OrderTracking from "./OrderTracking";
import Pricing from "./Pricing";
import Privacy from "./Privacy";
import ReceiptRedirect from "./ReceiptRedirect";
import ShopSurvey from "./ShopSurvey";
import ShopWebsite from "./ShopWebsite";
import Terms from "./Terms";
import Testimonials from "./Testimonials";
import VirtualRestaurant from "./VirtualRestaurant";
import WebsiteDesign from "./WebsiteDesign";
import WebsiteExamples from "./WebsiteExamples";

export {
  AutoPhone,
  Blog,
  BlogPost,
  Cart,
  ContactlessOrdering,
  ErrorPage,
  GetStarted,
  GoogleFrontPage,
  HomePage,
  MatchingShops,
  Oppties,
  OrderTracking,
  Pricing,
  Privacy,
  ReceiptRedirect,
  ShopSurvey,
  ShopWebsite,
  Terms,
  Testimonials,
  VirtualRestaurant,
  WebsiteDesign,
  WebsiteExamples,
};
