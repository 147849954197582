// BUTI DINERS, INC. All right Reserved ©

const EXAMPLE_WEBSITES = [
  {
    id: "zippbuild",
    name: "Zipp Build",
    url: "https://www.zippbuild.com",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Brush+Design+Website+Thumbnail.png",
  },
  {
    id: "phoDaiViet",
    name: "Pho Dai Viet",
    url: "https://www.phodaiviet.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Pho+Dai+Viet+Website+Thumbnail.png",
  },
  {
    id: "79degrees",
    name: "79 Degress",
    url: "https://www.79degreesia.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/79+Degree+Website+Thumbnail.png",
  },
  {
    id: "bunmee",
    name: "Bun Mee",
    url: "https://www.bun-mee.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sample+Websites/Bun+Mee+Website+Screenshot.png",
  },
  {
    id: "sangosushi",
    name: "Sango Sushi",
    url: "https://www.sangosushica.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sango+Sushi+Website+Thumbnail.png",
  },
  {
    id: "boulderpho",
    name: "Boulder Pho",
    url: "https://www.eatboulderpho.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Boulder+Pho+Website+Thumbnail.png",
  },
  {
    id: "ladyha",
    name: "Lady Ha",
    url: "https://www.ladyha.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Lady+Ha+Website+Thumbnail.png",
  },
  {
    id: "pearloftheorientwi",
    name: "Pearl of the Orient",
    url: "https://www.pearloftheorientwi.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Pearl+of+the+Orient+Website+Thumbnail.png",
  },
  {
    id: "newsuperpho",
    name: "New Super Pho",
    url: "https://www.newsuperpho.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/New+Super+Pho+Website+Thumbnail.png",
  },
  {
    id: "songlynn",
    name: "Song Lynn",
    url: "https://www.songlynn.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Song+Lynn+Website+Thumbnail.png",
  },
  {
    id: "gypsy_kitchen_dc",
    name: "Gypsy Kitchen DC",
    url: "https://www.gypsykitchendc.com",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sample+Websites/Gypsy+Kitchen+Website+Screenshot.png",
  },
  {
    id: "bbteahouse",
    name: "BB Tee House",
    url: "https://www.bbteehouse.com",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sample+Websites/BB+Tee+House+Website+Screenshot.png",
  },
  {
    id: "bebanhmi",
    name: "Be Banh Mi",
    url: "https://www.bebanhmi.net",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sample+Websites/Be+Banh+Mi+Website+Screenshot.png",
  },
  {
    id: "pholee",
    name: "Pho Lee & Bubble Tea",
    url: "https://www.pholeebubbletea.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Pho+Lee+Website+Thumbnail.png",
  },
  {
    id: "chicken_house",
    name: "Chicken House",
    url: "https://atlchickenhouse.com",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sample+Websites/Chicken+House+Website+Screenshot.png",
  },
  {
    id: "eve-wok",
    name: "Eve Wok",
    url: "https://www.eve-wok.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Sample+Websites/Eve+Wok+Website+Screenshot.png",
  },
  {
    id: "pho_cowboys",
    name: "Pho Cowboys",
    url: "https://www.phocowboys.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Pho+Cowboys+Website+Thumbnail.png",
  },
  {
    id: "nydeli",
    name: "NY Deli & Bagels",
    url: "https://www.nydelibagelcafe.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/NY+Deli+%26+Bagels+Website+Thumbnail.png",
  },
  {
    id: "bobakulture",
    name: "Boba Kulture",
    url: "https://www.bobakul.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Cuppa+House+Website+Thumbnail.png",
  },
  {
    id: "gongcha",
    name: "Gong Cha Atlanta",
    url: "https://www.gongchatea.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Gong+Cha+Website+Thumbnail.png",
  },
  {
    id: "saigonpho",
    name: "Saigon Pho",
    url: "https://www.saigonpho2.net/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Saigon+Pho+Website+Thumbnail.png",
  },
  {
    id: "saigonexpress",
    name: "Saigon Express",
    url: "https://www.saigonexpresslongmont.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Saigon+Express+Longmont+Website+Thumbnail.png",
  },
  {
    id: "phoAsia",
    name: "Pho Asia",
    url: "https://www.pho-asia.com",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Pho+Asia+Website+Thumbnail.png",
  },
  {
    id: "skillmanwok",
    name: "Skillman Wok",
    url: "https://www.skillmanwokcrowley.com/",
    businessType: "restaurant",
    thumbnailImg:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Skillman+Wok+Website+Thumbnail.png",
  },
];

const WEBSITE_EXAMPLES_HERO_IMG_URL =
  "https://skipli.s3.amazonaws.com/Skipli+Website/Website+Examples+Hero+Image.jpg";

export { EXAMPLE_WEBSITES, WEBSITE_EXAMPLES_HERO_IMG_URL };
