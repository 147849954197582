// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_modalBoxConfirmOrder__2ooEn {\n  height: 100vh;\n  width: 450px;\n  margin: 0 0 0 auto;\n  border-radius: 0;\n}\n\n.style_modalContainer__3RaQk {\n  padding: 20px;\n}\n\n@media only screen and (max-width: 450px) {\n  .style_modalBoxConfirmOrder__2ooEn {\n    width: 100vw;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/Modals/AddNewPaymentMethodModal/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAGA;EACE;IACE,YAAA;EAAF;AACF","sourcesContent":[".modalBoxConfirmOrder {\n  height: 100vh;\n  width: 450px;\n  margin: 0 0 0 auto;\n  border-radius: 0;\n}\n\n.modalContainer {\n  padding: 20px;\n}\n\n// ------------------------------------- Responsive -------------------------------------\n@media only screen and (max-width: 450px) {\n  .modalBoxConfirmOrder {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBoxConfirmOrder": "style_modalBoxConfirmOrder__2ooEn",
	"modalContainer": "style_modalContainer__3RaQk"
};
export default ___CSS_LOADER_EXPORT___;
