// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tabsContainer__1VLW9 {\n  margin-bottom: 2rem;\n}\n\n.style_tabLabel__3XKWs {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.style_icon__3Wx0n {\n  height: 20px;\n  margin-right: 10px;\n  width: 20px;\n}\n.style_icon__3Wx0n > svg {\n  height: inherit;\n  width: inherit;\n}\n\n.style_selected__2s3nT > svg {\n  fill: #eb1700 !important;\n}\n.style_selected__2s3nT > svg > g > path {\n  stroke: #eb1700 !important;\n}", "",{"version":3,"sources":["webpack://src/components/ConfirmOrderModules/DeliveryTypeSelector/style.module.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAHF;;AAMA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;AAHF;AAIE;EACE,eAAA;EACA,cAAA;AAFJ;;AAMA;EACE,wBAAA;AAHF;AAIE;EACE,0BAAA;AAFJ","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.tabsContainer {\n  margin-bottom: 2rem;\n}\n\n.tabLabel {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.icon {\n  height: 20px;\n  margin-right: 10px;\n  width: 20px;\n  & > svg {\n    height: inherit;\n    width: inherit;\n  }\n}\n\n.selected > svg {\n  fill: $skipli-red !important;\n  & > g > path {\n    stroke: $skipli-red !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": "style_tabsContainer__1VLW9",
	"tabLabel": "style_tabLabel__3XKWs",
	"icon": "style_icon__3Wx0n",
	"selected": "style_selected__2s3nT"
};
export default ___CSS_LOADER_EXPORT___;
