// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_subNavContainer__1v8oB {\n  display: flex;\n  align-items: center;\n  height: 50px;\n}\n\n.style_subNavItem__26kNT {\n  color: #000;\n  height: 100%;\n  margin-right: 20px;\n  text-transform: capitalize;\n}\n.style_subNavItem__26kNT.style_selected__sxrq4 {\n  border-bottom: 2px solid #009048;\n  border-radius: 0;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/components/Navbars/SubNavbar/style.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAHF;;AAMA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;AAHF;AAKE;EACE,gCAAA;EACA,gBAAA;EACA,iBAAA;AAHJ","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.subNavContainer {\n  display: flex;\n  align-items: center;\n  height: 50px;\n}\n\n.subNavItem {\n  color: #000;\n  height: 100%;\n  margin-right: 20px;\n  text-transform: capitalize;\n\n  &.selected {\n    border-bottom: 2px solid $primary;\n    border-radius: 0;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subNavContainer": "style_subNavContainer__1v8oB",
	"subNavItem": "style_subNavItem__26kNT",
	"selected": "style_selected__sxrq4"
};
export default ___CSS_LOADER_EXPORT___;
