// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_pageContainer__2HTN1 {\n  padding-top: 70px;\n}\n\n.style_sectionContainer__HiLr5 {\n  max-width: 1200px;\n  margin: 0 auto 100px auto;\n  padding: 0 30px;\n  position: relative;\n}\n\n@media only screen and (max-width: 700px) {\n  .style_sectionContainer__HiLr5 {\n    margin-bottom: 50px;\n  }\n}", "",{"version":3,"sources":["webpack://src/views/GoogleFrontPage/style.module.scss"],"names":[],"mappings":"AAIA;EACE,iBAAA;AAHF;;AAMA;EACE,iBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;AAHF;;AAMA;EACE;IACE,mBAAA;EAHF;AACF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.pageContainer {\n  padding-top: 70px;\n}\n\n.sectionContainer {\n  max-width: 1200px;\n  margin: 0 auto 100px auto;\n  padding: 0 30px;\n  position: relative;\n}\n\n@media only screen and (max-width: 700px) {\n  .sectionContainer {\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "style_pageContainer__2HTN1",
	"sectionContainer": "style_sectionContainer__HiLr5"
};
export default ___CSS_LOADER_EXPORT___;
