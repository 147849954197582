// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_modalClassname__2F9Ku {\n  height: 100vh;\n  width: 400px;\n  margin: auto 0 auto auto;\n}\n\n.style_contentContainer__zFri_ {\n  height: auto;\n  position: relative;\n  padding: 20px 20px 60px 20px;\n}\n\n@media only screen and (max-width: 500px) {\n  .style_modalClassname__2F9Ku {\n    width: 100vw;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/Modals/MobileNavOptions/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,wBAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;EACA,4BAAA;AADF;;AAIA;EACE;IACE,YAAA;EADF;AACF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.modalClassname {\n  height: 100vh;\n  width: 400px;\n  margin: auto 0 auto auto;\n}\n\n.contentContainer {\n  height: auto;\n  position: relative;\n  padding: 20px 20px 60px 20px;\n}\n\n@media only screen and (max-width: 500px) {\n  .modalClassname {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalClassname": "style_modalClassname__2F9Ku",
	"contentContainer": "style_contentContainer__zFri_"
};
export default ___CSS_LOADER_EXPORT___;
