// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_adminContentContainer__2nAds {\n  margin-left: 80px;\n  position: relative;\n  width: 100%;\n}\n\n.style_customerDesktop__1Muu5 {\n  padding-top: 96px;\n}\n\n.style_customerMobile__185U6 {\n  padding: 0 20px 6rem;\n}", "",{"version":3,"sources":["webpack://src/main/ShopSpecific/PageContentContainer/style.module.scss"],"names":[],"mappings":"AAIA;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;AAHF;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,oBAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.adminContentContainer {\n  margin-left: 80px;\n  position: relative;\n  width: 100%;\n}\n\n.customerDesktop {\n  padding-top: 96px;\n}\n\n.customerMobile {\n  padding: 0 20px 6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminContentContainer": "style_adminContentContainer__2nAds",
	"customerDesktop": "style_customerDesktop__1Muu5",
	"customerMobile": "style_customerMobile__185U6"
};
export default ___CSS_LOADER_EXPORT___;
