// BUTI DINERS, INC. All right Reserved ©

import { ReactComponent as AlertIcon } from "./Alert.svg";
import { ReactComponent as AppleLogoBlackIcon } from "./Apple-Logo-Black.svg";
import { ReactComponent as ArrowIcon } from "./ArrowPointRight.svg";
import { ReactComponent as BagFullIcon } from "./Bag-Full.svg";
import { ReactComponent as BagIcon } from "./Bag.svg";
import { ReactComponent as CarIcon } from "./Car.svg";
import { ReactComponent as CateringIcon } from "./Catering.svg";
import { ReactComponent as CheckIcon } from "./Check.svg";
import { ReactComponent as ChevronArrowIcon } from "./Chevron-Arrow.svg";
import { ReactComponent as ClockIcon } from "./Clock.svg";
import { ReactComponent as CloseIcon } from "./Close.svg";
import { ReactComponent as CloseSignIcon } from "./Close-Sign.svg";
import { ReactComponent as CreditCardIcon } from "./Credit-Card.svg";
import { ReactComponent as DairyIcon } from "./Dairy.svg";
import { ReactComponent as DancingDinosaurIcon } from "./Dancing-Dinosaur.svg";
import { ReactComponent as DeliverIcon } from "./Deliver.svg";
import { ReactComponent as DeliveryTruckIcon } from "./Delivery-Truck.svg";
import { ReactComponent as DiamondIcon } from "./Diamond.svg";
import { ReactComponent as DineInIcon } from "./DineIn.svg";
import { ReactComponent as DiningTableWithChairsIcon } from "./Dining-Table-With-Chairs.svg";
import { ReactComponent as DoordashIcon } from "./Doordash.svg";
import { ReactComponent as DuplicateIcon } from "./Duplicate.svg";
import { ReactComponent as EasyTechIcon } from "./Easy-Tech.svg";
import { ReactComponent as EcommerceIcon } from "./Ecommerce.svg";
import { ReactComponent as EggIcon } from "./Egg.svg";
import { ReactComponent as ExpandIcon } from "./Expand.svg";
import { ReactComponent as FacebookIcon } from "./Facebook.svg";
import { ReactComponent as FastOrderingIcon } from "./Fast-Ordering.svg";
import { ReactComponent as FilterIcon } from "./Filter.svg";
import { ReactComponent as FoodMenuFullIcon } from "./Food-Menu-Full.svg";
import { ReactComponent as FoodMenuIcon } from "./Food-Menu.svg";
import { ReactComponent as GlutenIcon } from "./Gluten.svg";
import { ReactComponent as GrubhubIcon } from "./Grubhub.svg";
import { ReactComponent as GoogleLogoBlackIcon } from "./Google-Logo-Black.svg";
import { ReactComponent as HappyDinosaurIcon } from "./Happy-Dinosaur.svg";
import { ReactComponent as HeartIcon } from "./Heart.svg";
import { ReactComponent as HomeIcon } from "./Home.svg";
import { ReactComponent as HyperlinkIcon } from "./Hyperlink.svg";
import { ReactComponent as InfoIcon } from "./Info.svg";
import { ReactComponent as InstagramIcon } from "./Instagram.svg";
import { ReactComponent as LinkedInIcon } from "./Linkedin.svg";
import { ReactComponent as ListIcon } from "./List.svg";
import { ReactComponent as LocationIcon } from "./Location.svg";
import { ReactComponent as LocationDarkIcon } from "./Location-Dark.svg";
import { ReactComponent as LunchBagIcon } from "./LunchBag.svg";
import { ReactComponent as MagnifyingGlassIcon } from "./Magnifying-Glass.svg";
import { ReactComponent as MenuIcon } from "./Menu.svg";
import { ReactComponent as MinusIcon } from "./Minus.svg";
import { ReactComponent as MoreIcon } from "./More.svg";
import { ReactComponent as NavigationIcon } from "./Navigation.svg";
import { ReactComponent as NewWindowIcon } from "./New-Window.svg";
import { ReactComponent as NoHighFeesIcon } from "./No-High-Fees.svg";
import { ReactComponent as NutIcon } from "./Nut.svg";
import { ReactComponent as OrdersIcon } from "./Orders.svg";
import { ReactComponent as PhoneIcon } from "./Phone.svg";
import { ReactComponent as PinterestIcon } from "./Pinterest.svg";
import { ReactComponent as PlayIcon } from "./Play.svg";
import { ReactComponent as PlusIcon } from "./Plus.svg";
import { ReactComponent as PorkIcon } from "./Pork.svg";
import { ReactComponent as PostmatesIcon } from "./Postmates.svg";
import { ReactComponent as RevenueIcon } from "./Revenue.svg";
import { ReactComponent as SeafoodIcon } from "./Seafood.svg";
import { ReactComponent as SendEmailIcon } from "./SendEmail.svg";
import { ReactComponent as SesameIcon } from "./Sesame.svg";
import { ReactComponent as ShellIcon } from "./Shell.svg";
import { ReactComponent as ShieldIcon } from "./Shield.svg";
import { ReactComponent as ShareIcon } from "./Share.svg";
import { ReactComponent as SkipliLogoCircleIcon } from "./Skipli-Logo-Circle.svg";
import { ReactComponent as SkipliLogoWhiteIcon } from "./Skipli-Logo-White.svg";
import { ReactComponent as SkipliLogoWithTextWhiteIcon } from "./Skipli-Logo-With-Text-White.svg";
import { ReactComponent as SkipliLogoWithTextIcon } from "./Skipli-Logo-With-Text.svg";
import { ReactComponent as SoyIcon } from "./Soy.svg";
import { ReactComponent as SpeechBubblesIcon } from "./Speech-Bubbles.svg";
import { ReactComponent as StarEmptyIcon } from "./Star-Empty.svg";
import { ReactComponent as StarFullIcon } from "./Star-Full.svg";
import { ReactComponent as StoreIcon } from "./Store.svg";
import { ReactComponent as TargetIcon } from "./Target.svg";
import { ReactComponent as TripAdvisorIcon } from "./TripAdvisor.svg";
import { ReactComponent as TwitterIcon } from "./Twitter.svg";
import { ReactComponent as UberEatsIcon } from "./Uber-Eats.svg";
import { ReactComponent as UserFullIcon } from "./User-Full.svg";
import { ReactComponent as UserIcon } from "./User.svg";
import { ReactComponent as WavingHandIcon } from "./Waving-Hand.svg";
import { ReactComponent as YelpIcon } from "./Yelp.svg";
import { ReactComponent as YoutubeIcon } from "./Youtube.svg";

export {
  AlertIcon,
  AppleLogoBlackIcon,
  ArrowIcon,
  BagFullIcon,
  BagIcon,
  CarIcon,
  CateringIcon,
  CheckIcon,
  ChevronArrowIcon,
  ClockIcon,
  CloseIcon,
  CloseSignIcon,
  CreditCardIcon,
  DairyIcon,
  DancingDinosaurIcon,
  DeliverIcon,
  DeliveryTruckIcon,
  DiamondIcon,
  DineInIcon,
  DiningTableWithChairsIcon,
  DoordashIcon,
  DuplicateIcon,
  EasyTechIcon,
  EcommerceIcon,
  EggIcon,
  ExpandIcon,
  FacebookIcon,
  FastOrderingIcon,
  FilterIcon,
  FoodMenuFullIcon,
  FoodMenuIcon,
  GlutenIcon,
  GrubhubIcon,
  GoogleLogoBlackIcon,
  HappyDinosaurIcon,
  HeartIcon,
  HomeIcon,
  HyperlinkIcon,
  InfoIcon,
  InstagramIcon,
  LinkedInIcon,
  ListIcon,
  LocationIcon,
  LocationDarkIcon,
  LunchBagIcon,
  MagnifyingGlassIcon,
  MenuIcon,
  MinusIcon,
  MoreIcon,
  NavigationIcon,
  NewWindowIcon,
  NoHighFeesIcon,
  NutIcon,
  OrdersIcon,
  PhoneIcon,
  PinterestIcon,
  PlayIcon,
  PlusIcon,
  PorkIcon,
  PostmatesIcon,
  RevenueIcon,
  SeafoodIcon,
  SendEmailIcon,
  SesameIcon,
  ShellIcon,
  ShareIcon,
  ShieldIcon,
  SkipliLogoCircleIcon,
  SkipliLogoWhiteIcon,
  SkipliLogoWithTextWhiteIcon,
  SkipliLogoWithTextIcon,
  SpeechBubblesIcon,
  StarEmptyIcon,
  StarFullIcon,
  StoreIcon,
  SoyIcon,
  TargetIcon,
  TripAdvisorIcon,
  TwitterIcon,
  UberEatsIcon,
  UserFullIcon,
  UserIcon,
  WavingHandIcon,
  YelpIcon,
  YoutubeIcon,
};
