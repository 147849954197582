// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_expansionPanelTitle__xH31C {\n  font-size: 1.1rem;\n}", "",{"version":3,"sources":["webpack://src/components/ConfirmOrderModules/style.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.expansionPanelTitle {\n  font-size: 1.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expansionPanelTitle": "style_expansionPanelTitle__xH31C"
};
export default ___CSS_LOADER_EXPORT___;
