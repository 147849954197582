// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_payMethodContainer__1YPGE {\n  display: flex;\n  padding: 0 5px;\n  align-items: center;\n  opacity: 0.2;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n.style_payMethodContainer__1YPGE.style_selected__2_zhB {\n  opacity: 1;\n}\n\n.style_iconContainer__1OyVc {\n  height: 40px;\n  margin-right: 10px;\n}\n\n.style_methodIcon__2h5jw {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n.style_methodIcon__2h5jw > svg {\n  height: 40px;\n}", "",{"version":3,"sources":["webpack://src/fields/PayMethodButton/style.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,wDAAA;AAHF;AAKE;EACE,UAAA;AAHJ;;AAOA;EACE,YAAA;EACA,kBAAA;AAJF;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAJF;AAME;EACE,YAAA;AAJJ","sourcesContent":["// BUTI Corp All right Reserved ©\n// Son That Ton\n// john@buti.io\n\n.payMethodContainer {\n  display: flex;\n  padding: 0 5px;\n  align-items: center;\n  opacity: 0.2;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n\n  &.selected {\n    opacity: 1;\n  }\n}\n\n.iconContainer {\n  height: 40px;\n  margin-right: 10px;\n}\n\n.methodIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n\n  & > svg {\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payMethodContainer": "style_payMethodContainer__1YPGE",
	"selected": "style_selected__2_zhB",
	"iconContainer": "style_iconContainer__1OyVc",
	"methodIcon": "style_methodIcon__2h5jw"
};
export default ___CSS_LOADER_EXPORT___;
