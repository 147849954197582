// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_timePicker__2ILbR {\n  border-radius: 10px;\n  padding: 0px 15px;\n  height: 40px;\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);\n}\n.style_timePicker__2ILbR > div {\n  border: none !important;\n}", "",{"version":3,"sources":["webpack://src/fields/TimePicker/style.module.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;EACA,iBAAA;EACA,YAAA;EAEA,2CAPO;AAIT;AAIE;EACE,uBAAA;AAFJ","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n$shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);\n\n.timePicker {\n  border-radius: 10px;\n  padding: 0px 15px;\n  height: 40px;\n  -webkit-box-shadow: $shadow;\n  box-shadow: $shadow;\n  & > div {\n    border: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timePicker": "style_timePicker__2ILbR"
};
export default ___CSS_LOADER_EXPORT___;
