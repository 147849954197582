// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_loadingMsg__1ZvPY {\n  background: #000;\n  border-radius: 10px;\n  color: #fff;\n  margin-bottom: 2rem;\n  padding: 15px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/ConfirmOrderModules/DeliveryQuotes/style.module.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.loadingMsg {\n  background: #000;\n  border-radius: 10px;\n  color: #fff;\n  margin-bottom: 2rem;\n  padding: 15px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingMsg": "style_loadingMsg__1ZvPY"
};
export default ___CSS_LOADER_EXPORT___;
