// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_optionContainer__H7oFY {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n  justify-content: space-between;\n}\n\n.style_textContainer__3zNu1 {\n  padding-right: 20px;\n  width: 85%;\n}\n\n.style_switchContainer__2OnAZ {\n  width: 15%;\n}\n\n.style_label__23YW_ {\n  font-size: 1rem;\n  margin-bottom: 10px;\n}\n\n.style_description__2K7cX {\n  font-size: 0.9rem;\n  line-height: 22px;\n}\n\n.style_switchIcon__C-uuj {\n  width: 100px;\n}", "",{"version":3,"sources":["webpack://src/components/Modals/FoodFiltersModal/Options/SwitchOption/style.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAHF;;AAMA;EACE,mBAAA;EACA,UAAA;AAHF;;AAMA;EACE,UAAA;AAHF;;AAMA;EACE,eAAA;EACA,mBAAA;AAHF;;AAMA;EACE,iBAAA;EACA,iBAAA;AAHF;;AAMA;EACE,YAAA;AAHF","sourcesContent":["// BUTI Corp All right Reserved ©\n// Son That Ton\n// sonton@gatech.edu\n\n.optionContainer {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n  justify-content: space-between;\n}\n\n.textContainer {\n  padding-right: 20px;\n  width: 85%;\n}\n\n.switchContainer {\n  width: 15%;\n}\n\n.label {\n  font-size: 1rem;\n  margin-bottom: 10px;\n}\n\n.description {\n  font-size: 0.9rem;\n  line-height: 22px;\n}\n\n.switchIcon {\n  width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionContainer": "style_optionContainer__H7oFY",
	"textContainer": "style_textContainer__3zNu1",
	"switchContainer": "style_switchContainer__2OnAZ",
	"label": "style_label__23YW_",
	"description": "style_description__2K7cX",
	"switchIcon": "style_switchIcon__C-uuj"
};
export default ___CSS_LOADER_EXPORT___;
