// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_modalBox__10knN {\n  height: 360px;\n  width: 640px;\n}\n\n@media only screen and (max-width: 700px) {\n  .style_modalBox__10knN {\n    width: 100vw;\n  }\n\n  .style_videoPlayer__11Jvp {\n    width: 100vw !important;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/Modals/VideoPlayerModal/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;AADF;;AAIA;EACE;IACE,YAAA;EADF;;EAIA;IACE,uBAAA;EADF;AACF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.modalBox {\n  height: 360px;\n  width: 640px;\n}\n\n@media only screen and (max-width: 700px) {\n  .modalBox {\n    width: 100vw;\n  }\n\n  .videoPlayer {\n    width: 100vw !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBox": "style_modalBox__10knN",
	"videoPlayer": "style_videoPlayer__11Jvp"
};
export default ___CSS_LOADER_EXPORT___;
