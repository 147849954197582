// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__1SpT1 {\n  display: flex;\n  justify-content: center;\n}\n\n.style_dot__Uqxrs {\n  -webkit-animation: style_container__1SpT1 0.6s infinite alternate;\n          animation: style_container__1SpT1 0.6s infinite alternate;\n  background-color: #000;\n  border-radius: 100px;\n  margin: 2px;\n  opacity: 1;\n  height: 8px;\n  width: 8px;\n}\n\n@-webkit-keyframes style_container__1SpT1 {\n  to {\n    opacity: 0.2;\n  }\n}\n\n@keyframes style_container__1SpT1 {\n  to {\n    opacity: 0.2;\n  }\n}\n.style_dot__Uqxrs:nth-child(2) {\n  -webkit-animation-delay: 0.2s;\n          animation-delay: 0.2s;\n}\n\n.style_dot__Uqxrs:nth-child(3) {\n  -webkit-animation-delay: 0.4s;\n          animation-delay: 0.4s;\n}", "",{"version":3,"sources":["webpack://src/fields/BouncingDotsLoader/style.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,uBAAA;AAHF;;AAMA;EACE,iEAAA;UAAA,yDAAA;EACA,sBAAA;EACA,oBAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;AAHF;;AAMA;EACE;IACE,YAAA;EAHF;AACF;;AAAA;EACE;IACE,YAAA;EAHF;AACF;AAOA;EACE,6BAAA;UAAA,qBAAA;AALF;;AAQA;EACE,6BAAA;UAAA,qBAAA;AALF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.container {\n  display: flex;\n  justify-content: center;\n}\n\n.dot {\n  animation: container 0.6s infinite alternate;\n  background-color: #000;\n  border-radius: 100px;\n  margin: 2px;\n  opacity: 1;\n  height: 8px;\n  width: 8px;\n}\n\n@keyframes container {\n  to {\n    opacity: 0.2;\n    // transform: translateY(-2px);\n  }\n}\n\n.dot:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.dot:nth-child(3) {\n  animation-delay: 0.4s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__1SpT1",
	"dot": "style_dot__Uqxrs"
};
export default ___CSS_LOADER_EXPORT___;
