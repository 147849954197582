// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_link__s-2tO {\n  color: #006aff;\n  text-decoration: none;\n}", "",{"version":3,"sources":["webpack://src/fields/Link/style.module.scss","webpack://src/styles/_variables.scss"],"names":[],"mappings":"AAIA;EACE,cCuBK;EDtBL,qBAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.link {\n  color: $info;\n  text-decoration: none;\n}\n","// BUTI DINERS, INC. All right Reserved ©\n\n$global-border-radius: 10px;\n$global-font-family: \"-apple-system\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\",\n  \"Helvetica Neue\", \"Ubuntu\", \"sans-serif\";\n$skipli-red: #eb1700;\n\n// Color\n$intro-background: #f5fdf9;\n\n$primary: #009048;\n$primary-light: #def2f1;\n$primary-lightest: #f2ffff;\n\n$black: #000;\n$black-light: #0b2135;\n$white: #fff;\n$yellow: #ffc555;\n\n$border-color: #e0e5fd;\n$border-color-dark: #d4dae0;\n\n// Text\n$text-color: #000;\n$text-color-light: #222222;\n$text-color-alt: #051146;\n$text-color-alt-light: #26305d;\n\n$info: rgb(0, 106, 255);\n$danger: #fd5458;\n$success: $primary;\n$warning: #ff633a;\n$white: #fff;\n$black: #000;\n$grey: #f4f4f5;\n$dark-grey: #525f7f;\n$grey-medium: #586271;\n// Transition\n$transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n$transition1: transform 0.2s;\n$transition2: all 100ms ease-in-out 0s;\n\n// Shadows\n$button-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),\n  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);\n$shadow: 0 0 30px 3px #e6e6e6;\n$shadow-light: 0px 1px 4px rgba(0, 0, 0, 0.16);\n$shadow2: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);\n$shadow-emphasize: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,\n  rgba(65, 155, 249, 0) 0px 0px 0px 0px,\n  rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,\n  rgba(159, 159, 164, 0.25) 0px 2px 10px 0px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "style_link__s-2tO"
};
export default ___CSS_LOADER_EXPORT___;
