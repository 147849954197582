// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_content_container__140OY {\n  max-width: 1200px;\n  margin: 60px auto;\n  padding: 0 30px;\n}\n\n.style_heading__3S5_D {\n  font-size: 50px;\n  margin-bottom: 30px;\n  margin: 0;\n}\n\n.style_description__3Rs4h {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 140%;\n  margin: 30px 0;\n}", "",{"version":3,"sources":["webpack://src/views/Blog/style.module.scss"],"names":[],"mappings":"AAIA;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;AAHF;;AAMA;EACE,eAAA;EACA,mBAAA;EACA,SAAA;AAHF;;AAMA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.content_container {\n  max-width: 1200px;\n  margin: 60px auto;\n  padding: 0 30px;\n}\n\n.heading {\n  font-size: 50px;\n  margin-bottom: 30px;\n  margin: 0;\n}\n\n.description {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 140%;\n  margin: 30px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content_container": "style_content_container__140OY",
	"heading": "style_heading__3S5_D",
	"description": "style_description__3Rs4h"
};
export default ___CSS_LOADER_EXPORT___;
