// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_submitButton__1yyM6 {\n  background: #eb1700;\n  font-size: 14px;\n  justify-content: space-between;\n  height: 40px;\n  width: 210px;\n  padding: 0 15px;\n}\n\n.style_priceCol__pX950 {\n  display: flex;\n  align-items: center;\n}\n\n.style_totalPriceBeforeSale__2wzCC {\n  margin-right: 10px;\n  opacity: 0.7;\n}\n\n.style_totalPriceAfterSale__3poQz {\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/components/CustomerMenuElements/MenuItemDetail/ItemDetailsForm/ItemButtons/SubmitButton/style.module.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;AAHF;;AAMA;EACE,kBAAA;EACA,YAAA;AAHF;;AAMA;EACE,iBAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.submitButton {\n  background: $skipli-red;\n  font-size: 14px;\n  justify-content: space-between;\n  height: 40px;\n  width: 210px;\n  padding: 0 15px;\n}\n\n.priceCol {\n  display: flex;\n  align-items: center;\n}\n\n.totalPriceBeforeSale {\n  margin-right: 10px;\n  opacity: 0.7;\n}\n\n.totalPriceAfterSale {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": "style_submitButton__1yyM6",
	"priceCol": "style_priceCol__pX950",
	"totalPriceBeforeSale": "style_totalPriceBeforeSale__2wzCC",
	"totalPriceAfterSale": "style_totalPriceAfterSale__3poQz"
};
export default ___CSS_LOADER_EXPORT___;
