// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__11AUl {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.style_keyContainer__1_LXg {\n  width: 30%;\n}\n\n.style_key__2b818 {\n  width: 100px;\n  background: #fff;\n  height: 50px;\n  margin-bottom: 10px;\n  font-size: 1.4rem;\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);\n  font-weight: bold;\n}\n\n.style_clearIcon__CLNJf {\n  height: 16px;\n  width: 16px;\n}\n\n.style_backSpaceIcon__q08wW {\n  height: 16px;\n  width: 16px;\n  -webkit-transform: rotate(180deg);\n          transform: rotate(180deg);\n}", "",{"version":3,"sources":["webpack://src/fields/Numberpad/style.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,eAAA;AAHF;;AAMA;EACE,UAAA;AAHF;;AAMA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EAEA,2CApBW;EAqBX,iBAAA;AAHF;;AAMA;EACE,YAAA;EACA,WAAA;AAHF;;AAMA;EACE,YAAA;EACA,WAAA;EACA,iCAAA;UAAA,yBAAA;AAHF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n$key-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);\n\n.container {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.keyContainer {\n  width: 30%;\n}\n\n.key {\n  width: 100px;\n  background: #fff;\n  height: 50px;\n  margin-bottom: 10px;\n  font-size: 1.4rem;\n  -webkit-box-shadow: $key-shadow;\n  box-shadow: $key-shadow;\n  font-weight: bold;\n}\n\n.clearIcon {\n  height: 16px;\n  width: 16px;\n}\n\n.backSpaceIcon {\n  height: 16px;\n  width: 16px;\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__11AUl",
	"keyContainer": "style_keyContainer__1_LXg",
	"key": "style_key__2b818",
	"clearIcon": "style_clearIcon__CLNJf",
	"backSpaceIcon": "style_backSpaceIcon__q08wW"
};
export default ___CSS_LOADER_EXPORT___;
