// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_list__3AyXX, .style_desktopList__2rCR0 {\n  display: flex;\n  overflow: auto;\n  padding-bottom: 5px;\n  padding: 0 0px 0px 5px;\n}\n\n.style_listItem__14yvu {\n  margin: 0 14px 14px 0;\n}\n\n.style_desktopList__2rCR0 {\n  margin-top: 1.2rem;\n  padding: 0;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 36px 50px;\n  display: grid;\n  list-style: none;\n  overflow: visible;\n}\n\n@media only screen and (max-width: 900px) {\n  .style_desktopList__2rCR0 {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n@media only screen and (max-width: 500px) {\n  .style_desktopList__2rCR0 {\n    grid-gap: 0;\n    margin-top: 0;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/CustomerMenuElements/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EACA,sBAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EAEE,kBAAA;EACA,UAAA;EACA,qCAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;AAFF;;AAKA;EACE;IACE,qCAAA;EAFF;AACF;AAKA;EACE;IACE,WAAA;IACA,aAAA;EAHF;AACF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.list {\n  display: flex;\n  overflow: auto;\n  padding-bottom: 5px;\n  padding: 0 0px 0px 5px;\n}\n\n.listItem {\n  margin: 0 14px 14px 0;\n}\n\n.desktopList {\n  @extend .list;\n  margin-top: 1.2rem;\n  padding: 0;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 36px 50px;\n  display: grid;\n  list-style: none;\n  overflow: visible;\n}\n\n@media only screen and (max-width: 900px) {\n  .desktopList {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n\n@media only screen and (max-width: 500px) {\n  .desktopList {\n    grid-gap: 0;\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "style_list__3AyXX",
	"desktopList": "style_desktopList__2rCR0",
	"listItem": "style_listItem__14yvu"
};
export default ___CSS_LOADER_EXPORT___;
