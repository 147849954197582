// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_overlay__1L7lI {\n  z-index: 100000;\n}\n\n.style_modalBox__3y55Z {\n  border-radius: 20px;\n  height: 250px;\n  width: 250px;\n}\n\n.style_contentContainer__1U1OZ {\n  display: flex;\n  justify-content: center;\n}\n\n.style_message__3rnJX {\n  font-size: 1.5rem;\n}", "",{"version":3,"sources":["webpack://src/components/Modals/LoadingModal/style.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,iBAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.overlay {\n  z-index: 100000;\n}\n\n.modalBox {\n  border-radius: 20px;\n  height: 250px;\n  width: 250px;\n}\n\n.contentContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.message {\n  font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "style_overlay__1L7lI",
	"modalBox": "style_modalBox__3y55Z",
	"contentContainer": "style_contentContainer__1U1OZ",
	"message": "style_message__3rnJX"
};
export default ___CSS_LOADER_EXPORT___;
