// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__HdDlr {\n  background: #fff;\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 50;\n}\n\n.style_content__1aTls {\n  max-width: 70vw;\n  margin: auto;\n  padding-top: 126px;\n}\n\n.style_navOption__6qU3s {\n  font-size: 1.4rem;\n  margin-bottom: 1.2rem;\n  text-transform: uppercase;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/WebsiteBuilder/Header/SideMenu/style.module.scss"],"names":[],"mappings":"AAKA;EACE,gBAAA;EACA,SAAA;EACA,OAAA;EACA,eAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;AAJF;;AAOA;EACE,eAAA;EACA,YAAA;EACA,kBAAA;AAJF;;AAOA;EACE,iBAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;AAJF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n@import \"~components/WebsiteBuilder/style.module.scss\";\n\n.container {\n  background: #fff;\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 50;\n}\n\n.content {\n  max-width: 70vw;\n  margin: auto;\n  padding-top: $website-header-mobile-height + 70px;\n}\n\n.navOption {\n  font-size: 1.4rem;\n  margin-bottom: 1.2rem;\n  text-transform: uppercase;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__HdDlr",
	"content": "style_content__1aTls",
	"navOption": "style_navOption__6qU3s"
};
export default ___CSS_LOADER_EXPORT___;
