// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_greetingMsg__1ymjq {\n  padding: 0 20px;\n}\n.style_greetingMsg__1ymjq > h1 {\n  font-size: 2rem;\n}\n.style_greetingMsg__1ymjq > p {\n  font-size: 1.2rem;\n  line-height: 30px;\n}\n\n.style_background__2FQ6c {\n  position: absolute;\n  display: flex;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: #fff;\n}\n\n.style_skipliLogoContainer__3NhS_ {\n  margin: auto;\n}\n.style_skipliLogoContainer__3NhS_ > svg {\n  height: 200px;\n  width: 200px;\n}", "",{"version":3,"sources":["webpack://src/main/style.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;AAEE;EACE,eAAA;AAAJ;AAEE;EACE,iBAAA;EACA,iBAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,aAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EAEA,gBAAA;AAFF;;AAKA;EACE,YAAA;AAFF;AAGE;EACE,aAAA;EACA,YAAA;AADJ","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.greetingMsg {\n  padding: 0 20px;\n  & > h1 {\n    font-size: 2rem;\n  }\n  & > p {\n    font-size: 1.2rem;\n    line-height: 30px;\n  }\n}\n\n.background {\n  position: absolute;\n  display: flex;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  // background: linear-gradient(to top, rgb(0, 164, 189), rgb(0, 189, 165));\n  background: #fff;\n}\n\n.skipliLogoContainer {\n  margin: auto;\n  & > svg {\n    height: 200px;\n    width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greetingMsg": "style_greetingMsg__1ymjq",
	"background": "style_background__2FQ6c",
	"skipliLogoContainer": "style_skipliLogoContainer__3NhS_"
};
export default ___CSS_LOADER_EXPORT___;
