// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import React from "react";
import PropTypes from "prop-types";

import { AllergySection, DietaryRestrictionsSection } from "./Sections";
import { LabelOption } from "./Options";

// Assets
import { ArrowIcon } from "assets/Icons";

// Fields
import { FullPageModal } from "fields";

// Style
import Style from "./style.module.scss";

const FoodFiltersModal = ({
  closeModal,
  numMatchedItems,
  onUpdateFoodPrefs,
  userFoodPreferences
}) => {
  const renderOptions = ({ options, path, sectionId }) => {
    switch (sectionId) {
      case "allergies":
        return (
          <div className={Style.allergyOptionsContainer}>
            <AllergySection
              options={options}
              onSelect={({ optionPath, newValue }) =>
                onUpdateFoodPrefs({
                  newValue,
                  path: path.concat("options", optionPath),
                  prefCriteria: sectionId
                })
              }
            />
          </div>
        );
      case "dietaryRestrictions": {
        return (
          <DietaryRestrictionsSection
            options={options}
            onSelect={({ optionPath, newValue }) => {
              onUpdateFoodPrefs({
                newValue,
                path: path.concat("options", optionPath),
                prefCriteria: sectionId
              });
            }}
          />
        );
      }
      default:
        return;
    }
  };

  const renderContent = () => {
    return userFoodPreferences.map((preference, index) => {
      const { description, id, label, options } = preference;
      return (
        <div className={Style.sectionContainer} key={id}>
          <h2 className={Style.sectionLabel}>{label}</h2>
          <p className={Style.sectionDescription}>{description}</p>
          <div>{renderOptions({ options, path: [index], sectionId: id })}</div>
        </div>
      );
    });
  };

  const renderFooter = () => (
    <div className={Style.footerButtonContainer}>
      <button className={Style.showDishesButton} onClick={closeModal}>
        Show {numMatchedItems} Dishes
      </button>
    </div>
  );

  return (
    <FullPageModal
      contentLabel="Menu Filters Modal"
      footer={renderFooter()}
      headerLeftElement={
        <button onClick={closeModal}>
          <ArrowIcon className={Style.arrowIcon} />
        </button>
      }
      headerRightElement={
        <button className={Style.saveInfoButton}>Save Info</button>
      }
      onCloseModal={() => {}}
    >
      <div>{renderContent()}</div>
    </FullPageModal>
  );
};

FoodFiltersModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  numMatchedItems: PropTypes.number.isRequired,
  onUpdateFoodPrefs: PropTypes.func.isRequired,
  userFoodPreferences: PropTypes.array.isRequired
};

export { FoodFiltersModal, LabelOption };
