// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_mobileBackgroundImg__2R_QJ {\n  background: #fff;\n  height: auto;\n  min-height: 100%;\n}\n.style_mobileBackgroundImg__2R_QJ.style_admin__2TzLg {\n  display: flex;\n}\n\n.style_menuSkeletonContainer__3ix87 {\n  max-width: 1240px;\n  margin: 2rem auto auto auto;\n}", "",{"version":3,"sources":["webpack://src/main/ShopSpecific/style.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;AADF;AAEE;EACE,aAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,2BAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.mobileBackgroundImg {\n  background: #fff;\n  height: auto;\n  min-height: 100%;\n  &.admin {\n    display: flex;\n  }\n}\n\n.menuSkeletonContainer {\n  max-width: 1240px;\n  margin: 2rem auto auto auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileBackgroundImg": "style_mobileBackgroundImg__2R_QJ",
	"admin": "style_admin__2TzLg",
	"menuSkeletonContainer": "style_menuSkeletonContainer__3ix87"
};
export default ___CSS_LOADER_EXPORT___;
