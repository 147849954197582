// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_labelOptionContainer__2thCZ {\n  display: flex;\n  flex-direction: column;\n  text-transform: capitalize;\n  font-size: 0.8rem;\n  align-items: center;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n.style_labelOptionContainer__2thCZ > p {\n  margin: 0;\n}\n\n.style_labelOptionIcon__2zID0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://src/components/Modals/FoodFiltersModal/Options/LabelOption/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,iBAAA;EACA,mBAAA;EACA,wDAAA;AADF;AAGE;EACE,SAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF","sourcesContent":["// BUTI Corp All right Reserved ©\n\n.labelOptionContainer {\n  display: flex;\n  flex-direction: column;\n  text-transform: capitalize;\n  font-size: 0.8rem;\n  align-items: center;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n\n  & > p {\n    margin: 0;\n  }\n}\n\n.labelOptionIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelOptionContainer": "style_labelOptionContainer__2thCZ",
	"labelOptionIcon": "style_labelOptionIcon__2zID0"
};
export default ___CSS_LOADER_EXPORT___;
