// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_shopName__2urJt {\n  margin-top: 0;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/components/CustomerProfileModules/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.shopName {\n  margin-top: 0;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shopName": "style_shopName__2urJt"
};
export default ___CSS_LOADER_EXPORT___;
