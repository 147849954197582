// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_menuNotFound__l41gL {\n  position: relative;\n}", "",{"version":3,"sources":["webpack://src/components/CustomerMenuElements/Menu/style.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.menuNotFound {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuNotFound": "style_menuNotFound__l41gL"
};
export default ___CSS_LOADER_EXPORT___;
