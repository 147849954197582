// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_optionsContainer__2lkXn {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 10px;\n}\n\n.style_field__1MHY2 {\n  margin-bottom: 20px;\n  max-width: 30%;\n  width: 30%;\n}", "",{"version":3,"sources":["webpack://src/fields/Input/RadioButtons/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,mBAAA;EACA,cAAA;EACA,UAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.optionsContainer {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 10px;\n}\n\n.field {\n  margin-bottom: 20px;\n  max-width: 30%;\n  width: 30%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsContainer": "style_optionsContainer__2lkXn",
	"field": "style_field__1MHY2"
};
export default ___CSS_LOADER_EXPORT___;
