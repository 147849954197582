// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_link__2u5oq {\n  display: flex;\n}\n\n.style_skipliLogo__1K7gM {\n  height: 55px;\n  width: 55px;\n}", "",{"version":3,"sources":["webpack://src/fields/SkipliLogo/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.link {\n  display: flex;\n}\n\n.skipliLogo {\n  height: 55px;\n  width: 55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "style_link__2u5oq",
	"skipliLogo": "style_skipliLogo__1K7gM"
};
export default ___CSS_LOADER_EXPORT___;
