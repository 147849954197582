// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const SwitchOption = ({ label, description, isSelected, onSelect }) => {
  return (
    <div className={Style.optionContainer}>
      <div className={Style.textContainer}>
        <div className={Style.label}>{label}</div>
        <div className={Style.description}>{description}</div>
      </div>
    </div>
  );
};

SwitchOption.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func
};

export default SwitchOption;
