// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_button__Y3oeH {\n  background: #eb1700;\n  justify-content: flex-start;\n  padding: 10px 15px;\n  width: 100%;\n}\n\n.style_iconContainer__2sAdD {\n  height: 42px;\n  margin-right: 15px;\n  width: 42px;\n  background: #fff;\n  border-radius: 100px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.style_iconContainer__2sAdD > svg {\n  height: 32px;\n  width: 32px;\n}\n\n.style_heading__32B9v {\n  font-size: 0.84rem;\n  margin: 0;\n}\n\n.style_callToAction__3Ecw_ {\n  display: flex;\n  margin-top: 0.4rem;\n  align-items: center;\n  font-size: 0.84rem;\n}\n.style_callToAction__3Ecw_ > svg {\n  height: 15px;\n  margin-left: 8px;\n  width: 15px;\n  fill: #fff;\n}", "",{"version":3,"sources":["webpack://src/components/ConfirmOrderModules/NewDeliveryFeature/style.module.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;EACA,2BAAA;EACA,kBAAA;EACA,WAAA;AAHF;;AAMA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHF;AAIE;EACE,YAAA;EACA,WAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,SAAA;AAHF;;AAMA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAHF;AAIE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;AAFJ","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n@import \"~styles/variables\";\n\n.button {\n  background: $skipli-red;\n  justify-content: flex-start;\n  padding: 10px 15px;\n  width: 100%;\n}\n\n.iconContainer {\n  height: 42px;\n  margin-right: 15px;\n  width: 42px;\n  background: #fff;\n  border-radius: 100px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  & > svg {\n    height: 32px;\n    width: 32px;\n  }\n}\n\n.heading {\n  font-size: 0.84rem;\n  margin: 0;\n}\n\n.callToAction {\n  display: flex;\n  margin-top: 0.4rem;\n  align-items: center;\n  font-size: 0.84rem;\n  & > svg {\n    height: 15px;\n    margin-left: 8px;\n    width: 15px;\n    fill: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "style_button__Y3oeH",
	"iconContainer": "style_iconContainer__2sAdD",
	"heading": "style_heading__32B9v",
	"callToAction": "style_callToAction__3Ecw_"
};
export default ___CSS_LOADER_EXPORT___;
