// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_checkbox__3YmWZ {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n.style_checkbox__3YmWZ > * {\n  cursor: pointer;\n  transition: all 100ms ease-in-out 0s;\n}\n.style_checkbox__3YmWZ > input[type=checkbox] {\n  background-color: #fff;\n  border: 1px solid #e0e0e0;\n  border-radius: 4px;\n  height: 30px;\n  margin: 0;\n  width: 30px;\n}\n.style_checkbox__3YmWZ > input[type=checkbox]:checked {\n  background-color: #000;\n  border-color: #000;\n}\n\n.style_label__1n7Js {\n  margin-left: 10px;\n}\n.style_label__1n7Js.style_checked__3iD1Y {\n  font-weight: bold;\n}\n\n.style_checkIcon__tNtr5 {\n  position: absolute;\n  fill: #fff;\n  height: 18px;\n  width: 18px;\n  left: 6px;\n}", "",{"version":3,"sources":["webpack://src/fields/Input/CheckBoxes/Checkbox/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAEE;EACE,eAAA;EACA,oCAAA;AAAJ;AAEE;EACE,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;AAAJ;AAEE;EACE,sBAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,iBAAA;AADF;AAEE;EACE,iBAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AADF","sourcesContent":["// BUTI DINERS, INC. All right Reserved ©\n\n.checkbox {\n  display: flex;\n  align-items: center;\n  position: relative;\n  & > * {\n    cursor: pointer;\n    transition: all 100ms ease-in-out 0s;\n  }\n  & > input[type=\"checkbox\"] {\n    background-color: #fff;\n    border: 1px solid #e0e0e0;\n    border-radius: 4px;\n    height: 30px;\n    margin: 0;\n    width: 30px;\n  }\n  & > input[type=\"checkbox\"]:checked {\n    background-color: #000;\n    border-color: #000;\n  }\n}\n\n.label {\n  margin-left: 10px;\n  &.checked {\n    font-weight: bold;\n  }\n}\n\n.checkIcon {\n  position: absolute;\n  fill: #fff;\n  height: 18px;\n  width: 18px;\n  left: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "style_checkbox__3YmWZ",
	"label": "style_label__1n7Js",
	"checked": "style_checked__3iD1Y",
	"checkIcon": "style_checkIcon__tNtr5"
};
export default ___CSS_LOADER_EXPORT___;
